import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from "rxjs";
import {CredentialsGuard} from "@app/guards/credentials.guard";

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Set auth header
    let credentials = localStorage.getItem(CredentialsGuard.credentialsKey);
    console.log('credentials', credentials)
    const authReq = req.clone({
      setHeaders: {
        Authorization: credentials
      }
    });

    return next.handle(authReq);
  }

}
