import { Component } from '@angular/core';

import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tec-customer ' + environment.name;
  protected readonly environment = environment;

  constructor() {
    if (!environment.logEnabled) {
      console.log = () => {};
    }
  }
}
