import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredentialsGuard  {

  static credentialsKey = 'credentials'

  constructor(
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const credentials = localStorage.getItem(CredentialsGuard.credentialsKey)
    console.log('credentials', credentials)
    console.log('url', next.url.toString())
    return true;
  }
}
